@import url(./font.css);

@tailwind utilities;

input,
select {
  outline: none;
  background: transparent;
}
input[type='file']::-webkit-file-upload-button {
  display: none;
}
input[type='number']::-webkit-inner-spin-button {
  display: none;
}
/* IMPORTANT: add styles here will impact .toggle-checkbox */
input[type='checkbox']:checked {
  background-color: currentColor;
  background-image: url('assets/icons/checkbox-icon.svg');
  background-repeat: no-repeat;
  background-size: 0.75rem 0.75rem;
  background-position: center;
}
input[type='radio']:checked {
  background-color: transparent;
  border: 1px solid currentColor !important;
  background-image: url('assets/icons/radio-icon.svg');
  background-repeat: no-repeat;
  background-size: 0.625rem 0.625rem;
  background-position: center;
}
input[type='radio'] {
  background-color: white !important;
}
input[type='radio']:disabled {
  background-color: #EBEBEB !important;
}
@-moz-document url-prefix() {
  input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
}
textarea {
  resize: none;
  outline: none;
}

button {
  outline: none;
  font-size: 0.875rem;
}

input:focus {
  outline: none;
}

html {
  color: #282829;
  #tooth-chart {
    .cls-1 {
      fill: currentColor;
    }
  }
}

[placeholder] {
  text-overflow: ellipsis;
}

@media only screen and (max-width: 1500px) and (min-width: 768px) {
  html {
    font-size: 12px;
  }
}

.retainer-product-info-container {
  background: linear-gradient(180deg, #005ae2 0%, #002279 58.95%);
}
.retainer-info-container {
  background: linear-gradient(180deg, #005ae2 0%, #0090ff 84.37%);
}
