input[type='time']::-webkit-calendar-picker-indicator {
  background: transparent;
  width: 100%;
  height: 100%;
  margin: 0px;
}

input[type='time']::-webkit-datetime-edit {
  display: none;
}

input[type='time'] {
  height: 100%;
  left: 0px;
  background: transparent;
  outline: none;
  font-size: 14px;
}
