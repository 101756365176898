.chat-message .custom-tab {
  font-size: 0.875rem;
  line-height: 1.5rem;
  padding: 0px;
}

.message-outer-container .deleted-message-block {
  background-color: #E0E0E1 !important;
}

.message-outer-container .deleted-message-block .deleted-message {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.message-outer-container .deleted-message-block .message-date {
  color: #6E6E73 !important;
}

.message-block-wrapper .image-wrapper .message-uploaded-img {
  height: 11.5rem;
  object-fit: cover;
}

@media screen and (max-width: 1100px) {
  .message-block-wrapper .image-wrapper .message-uploaded-img {
      height: 10.625rem;
  }
}

@media only screen and (max-width: 768px) {
  .chat-send-button {
    display: block;
  }
}

@media only screen and (min-width: 768px) {
  .chat-message-list-wrapper > div > div:first-child > .chat-date-separator {
    padding-top: 1rem;
  }

  .DraftEditor-root {
    min-height: 3rem;
  }
}

.chat-input-wrapper
  .chat-input-container
  .chat-message
  .chat-send-button:disabled {
  background-color: #ebebeb;
  color: #999999 !important;
}

.chat-message-container .chat-input-wrapper {
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  z-index: 0;
}

.chat-message-container .docs-wrapper .message-docs-filename {
  font-size: 0.875rem;
  text-decoration: underline;
}

.chat-message .chat-input-container {
  background: transparent !important;
}

.chat-message .internal .preview-section {
  background: #fef2d8;
}

.chat-message .external .preview-section {
  background: #fafbfc;
}

.chat-message .input-container {
  padding: 0 !important;
  border: none !important;
}

.chat-message .internal .input-container .chat-input {
  background: #fafbfc;
}

.mention-input-container{
  cursor: text !important;
  background-color: transparent;
}

.chat-message .internal .mention-input-container {
  background-color: #fef2d8;
}

/* chat edit message styles */
.chat-message .internal-edit .input-container {
  background: #fef2d8;
  border: 1px solid #ebebeb !important;;
}
.chat-message .internal-edit .mention-input-container {
  background-color: #fef2d8;
}

.internal-edit .input-container__top {
 padding: .5rem;
}


.chat-message .external .mention-input-container {
  background-color: #F8F8F9;
}


.patient-details-inbox .GCjLdK3sNqldOdTw7j59{
max-width: 330px;
}

.chat-message .chat-input::placeholder {
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.input-container .input-container__top .chat-input:empty:before {
  content: attr(placeholder);
  pointer-events: none;
  display: block; /* For Firefox */
  color: #97979b;
  font-size: 0.875rem;
}

.mobile .chat-input-container {
  margin-top: 16px;
  border: 1px solid #ebebeb;
  padding: 12px 16px !important;
  border-radius: 4px;
}

.chat-input {
  font-size: 0.875rem;
  color: #282829;
}

.mobile .chat-input {
  line-height: 1.5rem;
  background-color: white !important;
}

.chat-preview-popup {
  position: absolute;
}

.delete-modal {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 100;
}

.external .input-container__top {
  background-color: #F8F8F9;
}

.external .input-container__header {
  background-color: #F8F8F9;
}

.external .chat-input-container {
  background-color: white;
}
.external .input-container {
  background-color: #F8F8F9 !important;
}
.external .public-DraftEditorPlaceholder-root {
  color: #B1B1B4;
}

.internal .input-container__top {
  background: #fef2d8;
}

.internal .input-container__header {
  background: #fef2d8;
}

.closed-ticket .chat-input-wrapper {
  display: none;
}

.patient-details-inbox
  .chat-message-container
  .chat-input-wrapper
  .chat-input-container {
  padding: 8px !important;
}
.new-chat-form .internal-chat-input .chat-input-wrapper .chat-input-container {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.new-chat-form .internal-chat-input .custom-tab {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.patient-details-inbox .chat-message .custom-tab {
  font-size: 0.75rem;
  line-height: 1.25rem;
}

.patient-details-inbox .chat-message-container .message-text-block {
  max-width: 260px !important;
}

.closed-ticket .chat-input-attachment-icon {
  display: none;
}

.attachment-icon{
  display: flex ;
  align-items: center;
  justify-content: center;
}

.chat-uikit-wrapper .chat-date-separator {
  font-size: 0.75rem;
}

.ai-assist-disclaimer {
  line-height: 0.75rem !important;
}

.message-text-block .message-content {
  font-size: 0.875rem;
  padding-bottom: 0.5rem;
}

.message-content p, .message-content div {
  white-space: pre-wrap;
}

.message-block-wrapper .message-date {
  font-size: 0.75rem;
}

.message-block-wrapper .message-sender-name {
  font-size: 0.75rem;
  padding-bottom: 0.75rem;
}

.message-block-wrapper
  .image-wrapper
  .message-filename-container
  .message-image-filename {
  font-size: 0.875rem;
}
/* .message-block-wrapper .image-wrapper .message-filename-container .message-image-filename */
.patient-chat-content-container .message-media-block {
  max-width: 14rem;
}

@media only screen and (max-width: 1200px) {
  .patient-chat-content-container .message-media-block {
    max-width: 10rem;
  }
}

@media only screen and (max-width: 768px) {
  .chat-message-container .chat-input-wrapper {
    z-index: 10;
  }

  .chat-message-container .chat-input-wrapper .chat-input-container {
    background: transparent;
    padding: 0.5rem 1rem !important;
    margin-bottom: 1rem;
  }

  .chat-message .custom-tab {
    font-size: 0.75rem;
    line-height: 1.25rem;
  }

  .chat-message-container .message-text-block {
    max-width: 260px !important;
  }

  .chat-input-wrapper .chat-input-container .input-container {
    border: 1px solid #D2D2D7;
    border-radius: 0.5rem;
  }
}

:root {
  --chat-input-max-height: 28.5rem;
}

@media only screen and (max-width: 768px) {
  :root {
    --chat-input-max-height: 9rem;
  }
}


.public-DraftEditor-content {
  max-height: var(--chat-input-max-height);
  overflow-y: scroll;
}

.mention-input-container {
  max-height: var(--chat-input-max-height);
}


.public-DraftStyleDefault-ul, .public-DraftStyleDefault-ol {
  margin: 0 !important;
}

.internal-edit-message .message-footer-action-btn {
  display: none;
}

.external-edit-message .message-footer-action-btn {
  display: none;
}

.external-edit-message .message-text-block {
  border: 0 !important;
  background: rgba(204, 232, 255, 1) !important;
  box-shadow: 2px 4px 8px 0px rgba(0, 36, 90, 0.25);
}

.external-edit-message .message-text-block .message-content {
  color: rgba(26, 102, 136, 1) !important;
}

.external-edit-message .message-text-block .message-date {
  color: rgba(26, 102, 136, 1) !important;
}