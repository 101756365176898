.custom-date-selector .rdrMonth {
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  color: #282829;
  width: 18.75rem;
}

.year-selection .rdrMonthsVertical {
  display: none;
}

.year-selection-preview {
  width: 18.75rem;
}

.custom-date-selector .rdrMonth .rdrWeekDays {
  display: flex;
  align-items: center;
  padding: 0 0.25rem;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.custom-date-selector .rdrWeekDay {
  color: #999999;
  height: 2rem;
  width: 2rem;
  flex-basis: auto;
}

.custom-date-selector .rdrDayNumber {
  color: #282829;
  font-size: 0.875rem;
}

.custom-date-selector .rdrDayToday span {
  color: #282829;
  font-weight: 400;
}

.custom-date-selector .rdrDayToday span::after{
  content: none;
}

.custom-date-selector .rdrDayPassive .rdrDayNumber span {
  color: white;
}

.custom-date-selector
  .rdrDay:not(.rdrDayPassive)
  .rdrInRange
  ~ .rdrDayNumber
  span {
  color: #282829;
}

.custom-date-selector .rdrDay {
  height: 1.5rem;
  margin-bottom: 0.5rem;
}

.custom-date-selector .rdrInRange,
.custom-date-selector .rdrStartEdge,
.custom-date-selector .rdrEndEdge {
  top: 0px;
  bottom: 0px;
}

.custom-date-selector .rdrStartEdge {
  color: white;
  background-color: #D4EDFC;
  border-radius: 0px;
  margin-left: 50%;
}

.custom-date-selector .rdrEndEdge {
  color: white;
  background-color: #D4EDFC;
  border-radius: 0px;
  margin-right: 50%;
}

.custom-date-selector
  .rdrDay:not(.rdrDayPassive)
  .rdrStartEdge
  ~ .rdrDayNumber
  span {
  width: 1.5rem;
  border-radius: 2rem;
  height: 1.5rem;
  background: #2CAAE2;
  display: inline-flex;
  align-items: center;
  justify-content: center; 
}

.custom-date-selector
  .rdrDay:not(.rdrDayPassive)
  .rdrEndEdge
  ~ .rdrDayNumber
  span {
  width: 1.5rem;
  border-radius: 2rem;
  height: 1.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center; background: #2CAAE2;
}

/* preview */

.rdrDayStartOfMonth .rdrDayInPreview,
.rdrDayStartOfWeek .rdrDayInPreview,
.rdrDayStartOfMonth .rdrDayEndPreview,
.rdrDayStartOfWeek .rdrDayEndPreview {
  border: 1px solid #2caae2;
  border-right: 0;
}

.rdrDayEndOfMonth .rdrDayInPreview,
.rdrDayEndOfWeek .rdrDayInPreview,
.rdrDayEndOfMonth .rdrDayStartPreview,
.rdrDayEndOfWeek .rdrDayStartPreview {
  border: 1px solid #2caae2;
  border-left: 0;
}

.rdrDayStartOfMonth .rdrDayInPreview,
.rdrDayStartOfWeek .rdrDayInPreview {
  border: 1px solid #2caae2;
  border-right: 0px;
}
.rdrDayStartOfMonth .rdrDayEndPreview,
.rdrDayStartOfWeek .rdrDayEndPreview {
  border: 1px solid #2caae2;
}

.rdrDayEndOfMonth .rdrDayInPreview,
.rdrDayEndOfWeek .rdrDayInPreview,
.rdrDayEndOfMonth .rdrDayStartPreview,
.rdrDayEndOfWeek .rdrDayStartPreview {
  border-top: 1px solid #2caae2;
  border-bottom: 1px solid #2caae2;
}
.rdrDayStartPreview,
.rdrDayInPreview,
.rdrDayEndPreview {
  border: 0;
  top: 0px;
  bottom: 0px;
}

.rdrDayStartPreview {
  border: 1px solid #2caae2;
  margin-left: 25%;
  border-right: 0;
}
.rdrDayInPreview {
  border-top: 1px solid #2caae2;
  border-bottom: 1px solid #2caae2;
}

.rdrDayEndPreview {
  margin-right: 25%;
  border: 1px solid #2caae2;
  border-left: 0;
}

.rdrDayStartPreview.rdrDayEndPreview {
  border: 1px solid #2caae2;
}

.custom-date-selector .rdrDayDisabled {
  background-color: transparent;
}
