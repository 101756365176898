.fc-theme-standard{
    font-family: inherit;
    color: #282829;
    border-color: #EEEEEE !important;
    position: relative;
}
.fc-toolbar-chunk{
    display: flex;
}

.fc-theme-standard .fc-scrollgrid{
    border: none;
}


.fc .fc-button-primary{
    background: transparent;
    color: black !important;
    border: none;
    box-shadow: none !important;
}
.fc .fc-button-primary:hover{
    background: #EEEEEE;
    color: black;
    border: none;
    box-shadow: none !important;
}
.fc .fc-button-primary:active{
    background: none !important;
}

.fc-col-header-cell {
    border-right: 1px solid white !important;
}

.fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-number{
    background: #2CAAE2;
    color: white;
    border-radius:32px;
    height: 32px;
    width: 32px;
    text-align: center;
}
.fc .fc-daygrid-day.fc-day-today, .fc .fc-timegrid-col.fc-day-today{
    background: none;
}

.fc-daygrid-day-number {
    margin-right: 8px;
    margin-top: 8px;
    font-size: 1.06rem;
}

.fc .fc-day-other .fc-daygrid-day-top{
    opacity: 1;
    color: #C2C2C2;
}

.fc-theme-standard th{
    border-right: none;
}

.fc .fc-col-header-cell-cushion{
    padding: 8px;
    font-weight: normal;
}

.fc-toolbar-title {
    font-weight: bold;
}

.fc-theme-standard td, .fc-theme-standard th{
    border-color: #EEEEEE;
}

.fc .fc-timegrid-now-indicator-line{
    border-color: #2CAAE2;
}

.fc-timeGridWeek-view .fc-timegrid-now-indicator-arrow {
    display: none;
}

.fc-resourceTimeGridDay-view .fc-timegrid-now-indicator-arrow, .fc-timeGridDay-view  .fc-timegrid-now-indicator-arrow{
    display: block;
    border: none;
    background: #2CAAE2;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-left: 83%;
    margin-top: -4px;
    z-index: 999;
}
.fc-timegrid{
    font-size: 0.75rem;
}

.fc-col-header-cell .fc-day .fc-day-sun{
    border: none;
}

.fc-timegrid-divider{
    display: none;
}

.fc-time-grid .fc-slats td{
    height: 100px;
}

.fc-timegrid-slot{
    color: #89959D;
    border: 1px solid white;
    position: relative;
    border-left: none !important;
    border-right: none !important;
}

.fc .fc-timegrid-slot-label {
  vertical-align: top;
}

.fc-timeGridWeek-view .fc-timegrid-now-indicator-line::before  {
    background-color: #2CAAE2;
    content: "";
    position: absolute;
    top: -4px;
    left: -4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }

  .fc-theme-standard td .fc-timegrid-axis{
    border-left-color: white;
  }

  .allDayClassName{
    color: #89959D;
    position: relative;
  }

  .fc-timegrid-slot-label-frame {
      position: relative;
      height: 100%;
  }

  .fc-timegrid-slot-label-cushion {
    position: absolute;
    top: -8px;
    right: 0;
    margin-right: 4px;
    @apply text-xs;
  }

  .fc .fc-timegrid-slot {
    border: none;
  }

  .fc-timegrid-slot-lane {
    border-bottom: 1px solid #EEEEEE !important;
  }

  .fc-timeGridDay-view .fc-timegrid-col, .fc-timeGridDay-view .fc-daygrid-day{
    border: none !important;
  }

  .fc-col-header-cell{
      border-left: none !important;
  }

  .fc-dayGridMonth-view .fc-col-header-cell {
      text-align: right;
  }

  .fc-timeGridDay-view .fc-col-header-cell {
      text-align: left;
  }

  .fc-header-toolbar{
      margin: 0px !important;
  }

  .moreLinkClassName {
      font-size: 0.75rem;
      font-weight: 600;
      margin-left: 2px;
      background: white;
      display: flex;
      align-items: center;
  }

  td.fc-day-sun, th.fc-day-sun{
      border-left: none;
  }

  .fc-timegrid-axis {
    border-right: none !important;
  }

  .fc-timeGridDay-view .fc-col-header .fc-timegrid-axis{
      display: none;
  }

  .fc-timeGridDay-view .fc-col-header-cell-cushion {
    font-size: 1.25rem;
    padding: 0px;
    padding-bottom: 16px ;
  }

  .fc-media-screen .fc-timegrid-now-indicator-container {
    position: unset;
  }

  .fc-timegrid-body, .fc-daygrid-body {
      min-width: 100%;
  }

  .fc-daygrid-body {
      border-left: 1px solid #EEEEEE;
  }

  .fc-timegrid-body table, .fc-daygrid-body table {
    min-width: 100%;
}

.fc-col-header {
    min-width: 100%;
}

.fc-timegrid-slots {
    border-top: 1px solid #EEEEEE;
}

.fc-timegrid-slots table, .fc-timegrid-axis-chunk table {
    height: 2000px !important;
}

.fc-timegrid-col-events {
    width: 100%;
}

.fc-dayGridMonth-view .fc-h-event {
    background: none !important;
    border: none !important;
    margin-left: 2px;
}

.fc-event-main {
    overflow: hidden;
}

.fc-direction-ltr .fc-timegrid-col-events {
    margin: 0px;
}

.fc-timegrid-body colgroup col{
    min-width: 40px;
}

.fc-resourceTimeGridDay-view .fc-col-header-cell-cushion {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
}

.event-class {
    border-width: 0px;
}

.fc .fc-popover {
    z-index: 2;
}

.fc-daygrid-day-events{
    margin-bottom: 0px !important;
}

.fc-timegrid-slot[data-time='00:00:00'] .fc-timegrid-slot-label-frame {
    display: none;
}

.fc .fc-toolbar-title {
    @apply text-32px;
    @apply leading-10;
    font-weight: 700;
}


@media only screen and (max-width:768px) {
    .fc-timeGridDay-view .fc-col-header-cell{
        display: none;
    }

    .fc-timegrid-slots table, .fc-timegrid-axis-chunk table {
        height: 1500px !important;
    }
    .fc-timegrid-axis-chunk col{
        min-width: 45px;
    }

    .fc-theme-standard .fc-popover{
        right: 0px;
    }

    .fc .fc-more-popover .fc-popover-body{
        min-width: 100%;
    }

    .fc-timeGridDay-view .fc-scrollgrid-section td {
        border-right: none;
    }
}

/* Pending Appointment */
.fc .fc-timegrid-col-events .pending-appointment {
  background-image: url('/src/assets/icons/pendingAppointment.svg');
}

.fc-dayGridMonth-view + .fc-more-popover {
    visibility: hidden;
}