
@import 'react-calendar/dist/Calendar.css';

.react-calendar {
    font-family: inherit;
    border: none !important;
    color: #282829 !important;
    background: transparent;
}

.react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none;
    font-weight: normal;
}

.react-calendar__month-view__days__day--weekend {
    color: #282829;
}

.react-calendar__tile {
    font-size: 14px;
    font-weight: normal;
    height: 50px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.react-calendar__tile--snooze {
    font-size: 12px;
    height: 38px;
}

.react-calendar__tile abbr{
    display: block;
    line-height: 26px;
    height: 24px;
    width: 24px;
}

.react-calendar__tile--active, .react-calendar__tile--now, .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
    background: none;
    color: black;
}

.react-calendar__tile--active abbr {
    background: #2CAAE2;
    border-radius: 24px;
    color: white;
}

.react-calendar .react-calendar__navigation__next2-button, .react-calendar__navigation__prev2-button {
    display: none;
}

.react-calendar__navigation__label{
    background: transparent !important;
}

.react-calendar__navigation{
    margin-bottom: 0px;
}

.react-calendar__month-view__weekdays__weekday {
    @apply text-xs;
}

.react-calendar__tile:disabled, .react-calendar__navigation button[disabled] {
    background: none;
}

.react-calendar__tile--now:enabled:hover, .react-calendar__tile--now:enabled:focus,.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus{
    background: none;
}

.react-calendar__month-view__days__day:disabled {
    color: #999999;
}