.message-content strong {
    font-weight: 700;
}

.message-content ul {
    padding-inline-start: 1rem;
}

.message-content ul > li {
    list-style-type: disc;
}

.message-content ol {
    padding-inline-start: 1rem;
}

.message-content ol > li {
    list-style-type: decimal;
}

.message-content ol ol > li {
    list-style-type: lower-alpha;
}

.message-content ol > ol > ol > li {
    list-style-type: lower-roman;
}

.input-container ul > li {
    list-style-type: disc !important;
}