.new-chat-form .chat-input-container {
  background: transparent !important;
}

.new-chat-form .input-container {
  padding: 0 !important;
  border: none !important;
}
.new-chat-form .internal .mention-input-container{
  background: #fef2d8;
  @apply text-DEFAULT_TEXT;
}

.new-chat-form .external .mention-input-container {
  background-color: #F8F8F9;
  @apply text-DEFAULT_TEXT;
}

.mobile .chat-input-container {
  margin-top: 16px;
  border: 1px solid #ebebeb;
  padding: 5px 16px !important;
  border-radius: 4px;
}
.mobile .chat-input:empty:before {
  content: attr(placeholder);
  pointer-events: none;
  display: block; /* For Firefox */
  color: #b8b8b5 !important;
  font-size: 14px;
}

.mobile .chat-input {
  font-size: 14px;
  line-height: 24px;
  background-color: white !important;
}

.new-chat-form .chat-send-button {
  display: none;
}
.error .chat-input-container {
  border: 1px solid #D91E36;
}

.new-chat-form .error {
  border: none;
  background: white;
}