@tailwind base;
@tailwind components;

@screen md {
  .customResponsiveModalHeight {
    height: auto;
  }
}

@screen xxs {
  .customResponsiveModalHeight {
    height: calc(100vh - 3.5rem);
  }
}
