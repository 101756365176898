.customRoot {
  z-index: 999;
  position: fixed;
}
.disclaimerRoot {
  z-index: 4;
}

.react-responsive-modal-root {
  position: absolute;
}

.customOverlay {
  background-color: rgba(40, 40, 41, 0.65);
}
@media only screen and (min-width: 768px) {
  .react-responsive-modal-overlay {
    position: absolute;
  }
  .react-responsive-modal-root {
    position: absolute;
  }
}
.disclaimerOverlay {
  background-color: rgba(40, 40, 41, 0.75);
}

.noOverlay {
  background-color: transparent;
}

.noOverlayModal {
  box-shadow: 2px 4px 8px rgba(142, 153, 168, 0.15);
  border-radius: 8px;
  padding: 0px;
}

.customModal {
  @apply rounded-xl shadow-none p-0;
  overflow: visible;
}

.containerModal {
  overscroll-behavior: contain;
}

/* top and bottom margin for large modals */
.react-responsive-modal-modal {
  margin: 30px 0px 30px 0px;
  max-width: 100% !important;
}
.react-responsive-modal-container {
  overflow-y: auto;
}

@media only screen and (max-width: 768px) {
  .noOverlayModal {
    width: 100%;
    box-shadow: none;
    border-radius: 0px;
    vertical-align: top;
    margin: 3.5rem 0px 0px 0px; /* adjsting position of modal */
  }
  .disclaimerRoot {
    z-index: 999;
  }
}

.react-responsive-modal-overlay,
.react-responsive-modal-container,
.react-responsive-modal-modal {
  animation-fill-mode: forwards !important;
}
