@font-face {
  font-family: sofia pro;
  font-style: normal;
  font-weight: 400;
  src: local('Sofia Pro'),
    url('assets/fonts/SofiaPro-Regular.otf') format('woff');
}

@font-face {
  font-family: sofia pro;
  font-style: normal;
  font-weight: 250;
  src: local('Sofia Pro'),
    url('assets/fonts/SofiaPro-ExtraLight.otf') format('woff');
}

@font-face {
  font-family: sofia pro;
  font-style: normal;
  font-weight: 300;
  src: local('Sofia Pro'), url('assets/fonts/SofiaPro-Light.otf') format('woff');
}

@font-face {
  font-family: sofia pro;
  font-style: normal;
  font-weight: 500;
  src: local('Sofia Pro'),
    url('assets/fonts/SofiaPro-Medium.otf') format('woff');
}

@font-face {
  font-family: sofia pro;
  font-style: normal;
  font-weight: 600;
  src: local('Sofia Pro'),
    url('assets/fonts/SofiaPro-SemiBold.otf') format('woff');
}

@font-face {
  font-family: sofia pro;
  font-style: normal;
  font-weight: 700;
  src: local('Sofia Pro'), url('assets/fonts/SofiaPro-Bold.otf') format('woff');
}

@font-face {
  font-family: sofia pro;
  font-style: normal;
  font-weight: 900;
  src: local('Sofia Pro'), url('assets/fonts/SofiaPro-Black.otf') format('woff');
}
