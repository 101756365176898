.toggle-checkbox {
    background: #EBEBEB;
    border-radius: 3rem;
    appearance: none;
    cursor: pointer;
    transition: 0.4s;
    position: relative;
}

.toggle-checkbox[type="checkbox"]:checked {
    background-color: #2CAAE2;
    background-image: none;
}

.toggle-checkbox[type="checkbox"]::after {
    position: absolute;
    content: "";
    width: 0.75rem;
    height: 0.75rem;
    top: 0.125rem;
    left: 0.125rem;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
}

.toggle-checkbox:checked[type="checkbox"]::after {
    left: 1.125rem;
}