

.react-carousel-dots-dot.active{
	background-color: white !important;
	border: 0 !important;
	opacity: 1;
}
.react-carousel-dots-dot {
	background-color: white !important;
	border: 0 !important;
	opacity: 0.6;
}
.chat-preview-popup__container {
	display: flex;
	justify-content: center;
	align-items: center;
}


.chat-preview-popup__img {
	border-radius: 0.5rem;
	max-width: min(91vw, 900px);
	max-height: min(90vh, 600px);
}

.chat-preview-popup__close-button {
	position: absolute;
	top: 1.625rem;
	right: 1.7rem;
}