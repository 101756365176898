/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

:root {
  --scrollbar-width: 0.375rem;
  --scrollbar-thumb-color: #b8b8b8;
  --appbar-height: 3.5rem;
  --private-route-px: 2.5rem;
}

button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}

@screen md {
  .chat-content-container-internal {
    height: calc(100vh - 10.75rem);
    /* height of the inbox filter and title reduced from widow height */
  }

  .chat-content-container-external {
    height: calc(100vh - 3.5rem);
    /* height of the inbox filter and title reduced from widow height */
  }

  .ticket-card-container-external {
    height: calc(100vh - 10.625rem);
  }

  .chat-message-box {
    height: calc(100vh - 3.5rem);
  }

  .patient-chat-content-container {
    height: calc(100vh - 11rem);
  }

  .patient-chat-list-container {
    height: calc(100vh - 8.3rem);
  }

  .patient-chat-list-container-internal {
    max-height: calc(100vh - 25.5rem);
  }

  .bulk-add-ticket-list {
    height: calc(100vh - 16.5rem);
  }

  .patient-chat-list-container .chat-send-button {
    display: none !important;
  }

  .patient-chat-list-container-internal .chat-send-button {
    display: none !important;
  }

  .patient-chat-list-container .chat-send-button--editing {
    display: inline-block !important;
  }

  .patient-chat-list-container-internal .chat-send-button--editing {
    display: inline-block !important;
  }

  /* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: var(--scrollbar-width);
    scrollbar-color: transparent;
  }

  *:hover {
    scrollbar-color: var(--scrollbar-thumb-color);
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: var(--scrollbar-width);
    height: 5px;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 2px;
  }

  *:hover::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb-color);
  }
}

@screen xxs {
  .patient-chat-list-container {
    height: calc(100vh - 18.3rem);
  }

  .patient-chat-list-container-internal {
    height: calc(100vh - 20.5rem);
  }

  .private-route {
    padding: 1.5rem 1rem;
  }
}

@screen md {
  .private-route {
    padding-right: var(--private-route-px);
    padding-left: var(--private-route-px);
    padding-bottom: 0px;
    padding-top: 2rem;

  }

  .private-route:has(.no-layout-padding) {
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 0;
    padding-top: 0;
  }

  .chat-box-layout {
    margin-right: calc(var(--private-route-px) * -1);
    margin-left: calc(var(--private-route-px) * -1);
    margin-top: -2rem;
    height: calc(100% + 2rem);
    overflow: hidden;
  }

  .patient-container {
    /* adding privateRoute right padding */
    width: calc(100% + var(--private-route-px));
  }

  .patient-chat-list-container-internal {
    height: auto;
  }
}

@media only screen and (max-width: 425px) {
  .ticket-list-scroll {
    height: calc(100vh - 242px);
    overflow-y: auto;
  }
}

@media only screen and (max-width: 768px) {
  *::-webkit-scrollbar {
    display: none;
  }

  * {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .scrollbar-visible-mobile::-webkit-scrollbar {
    display: block;
    width: var(--scrollbar-width);
    height: 5px;
  }

  .scrollbar-visible-mobile::-webkit-scrollbar-track {
    background: transparent;
  }

  .scrollbar-visible-mobile::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb-color);
    border-radius: 2px;
  }

  .scrollbar-visible-mobile {
    scrollbar-width: var(--scrollbar-width);
    scrollbar-color: var(--scrollbar-thumb-color) transparent;
    -ms-overflow-style: auto;
  }
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(3.5);
    transform: scale(3.5);
    opacity: 0;
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(3.5);
    transform: scale(3.5);
    opacity: 0;
  }
}

.scale-up-center {
  -webkit-animation: scale-up-center 1.2s ease-in-out alternate backwards;
  animation: scale-up-center 1.2s ease-in-out alternate backwards;
}

.leading-56px {
  line-height: 3.5rem;
  /* for consent form */
}

.list-lower-alpha {
  list-style-type: 'lower-alpha';
}

.list-none {
  list-style-type: 'none';
}

.list-disc {
  list-style-type: 'disc';
}

.list-decimal {
  list-style-type: decimal;
}

.list-square {
  list-style-type: 'square';
}

.list-upper-roman {
  list-style-type: 'upper-roman';
}

.list-lower-roman {
  list-style-type: 'lower-roman';
}

.slick-slide>div {
  margin: 0 10px;
}

.slick-dots li.slick-active button:before {
  color: #2caae2 !important;
  font-size: 8px;
}

.slick-dots li button:before {
  color: #2caae2 !important;
  opacity: 0.25;
}

.linerGradientBg {
  background-image: linear-gradient(90deg,
      hsl(0deg 0% 100%) 0%,
      hsl(198deg 91% 99%) 9%,
      hsl(198deg 91% 98%) 18%,
      hsl(198deg 91% 96%) 28%,
      hsl(198deg 91% 96%) 37%,
      hsl(198deg 91% 97%) 46%,
      hsl(198deg 91% 98%) 55%,
      hsl(198deg 91% 100%) 64%,
      hsl(46deg 89% 99%) 73%,
      hsl(46deg 90% 97%) 82%,
      hsl(46deg 90% 94%) 91%,
      hsl(47deg 90% 92%) 100%);
}

.financeGradientBg {
  background: linear-gradient(311.2deg, rgba(255, 218, 89, .15) 12.92%, rgba(255, 255, 255, 0.25) 50.37%, rgba(44, 170, 226, .1) 91.64%);
}